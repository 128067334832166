<template>
	<div class="content">
		<vue-headful
			:title="pageTitle"
		/>
		
		<loading :active.sync="isLoading"
		         :is-full-page="true"/>
		
		<main v-if="!isLoading">
			<h1>{{ $t('privacy_policy') }}</h1>
			<span v-if="museum.locale.privacyPolicy" class="info" v-html="museum.locale.privacyPolicy" />
			<span v-else class="info">{{ $t('no_resources') }}</span>
		</main>
		
		<footer>
			<button class="button-white" @click="goBack()">
				<i class="mm-icon mm-icon-close big"/>
			</button>
		</footer>
	</div>
</template>

<script>
const {debug} = require("@/misc/debug");
import * as mutationTypes from "@/store/mutation-types";
import { mapActions, mapState } from "vuex";
import Loading from "vue-loading-overlay";
import {setFullScreen} from "@/store/helpers";

export default {
	name: "PrivacyPolicy",
	
	components: {
		Loading,
	},
	
	data() {
		return {
			encrypted: this.$route.params.idMuseum,
			idMuseum: "0",
		};
	},
	
	computed: {
		...mapState({
			museum: state => state.museum.museum,
			isLoading: state => state.app.isLoading,
			isNetworkOnline: state => state.app.isNetworkOnline,
		}),
		
		pageTitle() {
			let locationName = this.$i18n.t('privacy_policy');
			
			let museumName = (this.museum?.name)
				? this.museum.name
				: this.museum?.locale?.name
					? this.museum.locale.name
					: "MuseumMate";
			
			return locationName + ' | ' + museumName;
		},
	},
	
	created() {
		debug.log("[PrivacyPolicy] Creating view");
		this.$store.commit('app/' + mutationTypes.SET_IS_LOADING, true);
		
		try {
			let decryption = (atob(this.encrypted));
			this.idMuseum = decryption.split('-')[0];
			
			this.loadMuseum(this.idMuseum)
				.finally(() => {
					this.$store.commit('app/' + mutationTypes.SET_IS_LOADING, false);
				});
		} catch(e) {
			if (process.env.VUE_APP_DEBUG_MODE == "true") console.error("Error deciphering: ", e);
			this.$store.commit('app/' + mutationTypes.SET_IS_LOADING, false);
		}
	},
	
	methods: {
		...mapActions('app', ['loadMuseum']),
		
		goBack() {
			setFullScreen();
			this.$router.go(-1);
		},
	}
};
</script>

<style lang="scss" scoped>
@import '../../theme/colors';

.content {
  height: 100%;
  overflow-x: hidden;

  h1 {
    font-weight: normal;
    border-bottom: 1px solid $gray-lightest;
    padding: 20px;
    width: 100%;
    font-size: 22px;
    position: fixed;
    top: 0;
    background-color: white;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .info {
    display: block;
    padding: 70px 30px 110px 30px;

    ::v-deep h2 {
      font-family: Montserrat, sans-serif;
      font-size: 18px;
      font-weight: bolder;
    }

    ::v-deep p,
    ::v-deep ul {
      font-family: Montserrat, sans-serif;
      margin-block-start: 1em;
      margin-block-end: 1em;
    }

    ::v-deep ul {
      padding-left: 40px;
    }

    ::v-deep strong {
      font-family: Montserrat, sans-serif;
    }
  }

  footer {
    height: 90px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-items: center;
    position: fixed;
    bottom: 0;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);

    .button-white {
      border: none;
      background-color: white;
      height: 100%;
      color: black;
    }
  }
}
</style>
